<template>
  <section-container>
    <section class="my-skills">
      <div class="skills">
        <h3>main skills</h3>
        <div class="main-skills">
          <skills-view :skills="getSkills"></skills-view>
        </div>
        <h3>other skills</h3>
        <div class="other-skills">
          <span v-for=" otherSkill, index  in getOtherSkills" :key="index">{{ otherSkill }}</span>
        </div>
      </div>
    </section>
  </section-container>
</template>

<script>
import SkillsView from './SkillsView.vue'
export default {
  components: {
    SkillsView
  },
  computed: {
    getSkills() {
      return this.$store.getters["home/getSkills"]
    },
    getOtherSkills() {
      return this.$store.getters["home/getOtherSkills"]
    },
  },
}
</script>
<style scoped lang="scss">
@import '../../scss/vars';


.my-skills {
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin: 5px auto;

  @media (max-width: 767px) {
    flex-direction: column;
    width: 90%;
  }

  img {
    width: calc(60% - 10px);

    @media (max-width: 767px) {
      width: 100%;
      margin: 10px auto;
    }
  }

  .skills {
    width: 80%;

    @media (max-width: 767px) {
      width: 100%;
    }

    >h3 {
      text-transform: capitalize;
      background-color: #ddd;
      margin-top: 10px;
      padding: 5px 10px;
    }

    .main-skills {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
      gap: 10px;

      @media (max-width: 767px) {
        grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
      }
    }

    .other-skills {
      width: 100%;
      border: 1px solid #41b883;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      align-items: center;
      padding: 5px 5px;

      @media (max-width: 767px) {
        justify-content: center;
      }

      span {
        background-color: #ddd;
        margin: 5px 2px;
        padding: 5px 10px;
        // border-radius: 10px;
        color: #41b883;
      }
    }
  }
}
</style>
