import mutations from "./mutations.js";
import actions from "./actions.js";
import getters from "./getters.js";

export default {
  namespaced: true,
  state() {
    return {
      services: [
        {
          id: "1",
          serviceName: "portfolio-page",
          serviceTitle: "portfolio page",
          serviceDesc:
            "A portfolio web page is an online platform where individuals showcase their professional skills, experiences, and projects. It serves as a digital resume, highlighting key achievements, detailed project descriptions, and visual examples of work. This page is designed to attract potential clients, employers, or collaborators by providing a comprehensive overview of an individual’s capabilities and accomplishments in a visually appealing and easily navigable format.",
          features: [
            {
              id: "1",
              featureName: "Introduction/Bio",
              featureDesc:
                "A brief introduction about yourself, including your background, skills, and professional goals. A professional photo",
            },
            {
              id: "2",
              featureName: "Contact Information",
              featureDesc:
                "    Easy access to your email, phone number, and social media profiles. A contact form for visitors to reach out directly from the website.",
            },
            {
              id: "3",
              featureName: "Portfolio/Projects Section",
              featureDesc:
                "Showcase your best projects with descriptions, technologies used, and your role in each project. Include images, videos, or live demos to illustrate your work. Option to filter projects by category or technology.",
            },
            {
              id: "4",
              featureName: "Skills and Expertise",
              featureDesc:
                "A list or infographic showcasing your technical skills, languages, tools, and frameworks you are proficient in. Rate your proficiency levels (e.g., beginner, intermediate, advanced).",
            },
            {
              id: "5",
              featureName: "Experience and Education",
              featureDesc:
                "Detailed information about your work experience, including company names, roles, responsibilities, and achievements.Education history with institutions, degrees, and graduation dates.",
            },
            {
              id: "6",
              featureName: "Testimonials/Recommendations",
              featureDesc:
                "Quotes or reviews from clients, colleagues, or supervisors.LinkedIn recommendations or other professional endorsements.",
            },
            {
              id: "7",
              featureName: "Blog or Articles Section",
              featureDesc:
                "    Optional section for sharing articles, tutorials, or thoughts related to your field.Helps demonstrate your knowledge and thought leadership.",
            },
            {
              id: "8",
              featureName: "Resume/CV Download",
              featureDesc:
                "An option to download your updated resume or CV in PDF format.",
            },
            {
              id: "9",
              featureName: "Responsive Design",
              featureDesc:
                "Ensure the portfolio is accessible and visually appealing on various devices, including desktops, tablets, and smartphones.",
            },
            {
              id: "10",
              featureName: "Interactive Elements",
              featureDesc:
                "Interactive elements such as animations, hover effects, and dynamic content to enhance user engagement.",
            },
            {
              id: "11",
              featureName: "SEO Optimization",
              featureDesc:
                "Implement SEO best practices to ensure your portfolio ranks well in search engine results.",
            },
            {
              id: "12",
              featureName: "Analytics",
              featureDesc:
                "Integrate analytics to track visitor interactions, page views, and other metrics to understand how users are engaging with your portfolio.",
            },
          ],
        },
        // {
        //   id: "2",
        //   serviceName: "special-web-application",
        //   serviceTitle: "special web application",
        //   serviceDesc:
        //     "A special web application is a custom-designed software tool accessed through a web browser, tailored to meet specific business or user needs. Unlike general-purpose applications, it addresses particular functions or problems, often integrating unique features and functionalities to streamline workflows, enhance user experience, and provide targeted solutions. These applications are built with scalability, flexibility, and user-specific requirements in mind, making them essential for businesses seeking to optimize their operations and deliver specialized services online.",
        //   features: [
        //     {
        //       id: "1",
        //       featureName: "Custom Functionality",
        //       featureDesc:
        //         "    Tailored features that address specific business processes or user requirements.Unique workflows and tools designed to solve particular problems.",
        //     },
        //     {
        //       id: "2",
        //       featureName: "User Authentication and Authorization",
        //       featureDesc:
        //         "    Secure login and registration systems.Role-based access control to ensure that users can only access relevant features and data.",
        //     },
        //     {
        //       id: "3",
        //       featureName: "Responsive Design",
        //       featureDesc:
        //         "    Ensures the application works seamlessly across various devices and screen sizes. Mobile-friendly interface for accessibility on smartphones and tablets.",
        //     },
        //     {
        //       id: "4",
        //       featureName: "Data Management and Storage",
        //       featureDesc:
        //         "Robust database integration for storing and managing data efficiently.Features for data entry, retrieval, updating, and deletion",
        //     },
        //     {
        //       id: "5",
        //       featureName: "Integration with Other Systems",
        //       featureDesc:
        //         "    Ability to integrate with third-party services, APIs, or other software systems.Seamless data exchange and interoperability with existing tools.",
        //     },
        //     {
        //       id: "6",
        //       featureName: "User-Friendly Interface",
        //       featureDesc:
        //         "    Intuitive and easy-to-navigate UI/UX design. Clear layout and design elements that enhance user experience.",
        //     },
        //     {
        //       id: "7",
        //       featureName: "Performance Optimization",
        //       featureDesc:
        //         "    Efficient code and architecture to ensure fast load times and smooth operation. Scalability to handle increasing amounts of data and users without degradation in performance.",
        //     },
        //     {
        //       id: "8",
        //       featureName: "Security Features",
        //       featureDesc:
        //         "    Implementation of security best practices to protect user data and prevent breaches. Encryption, secure data transmission, and regular security updates.",
        //     },
        //     {
        //       id: "9",
        //       featureName: "Analytics and Reporting",
        //       featureDesc:
        //         "    Built-in tools for tracking user activity and performance metrics. Customizable reports and dashboards for data analysis and decision-making.",
        //     },
        //     {
        //       id: "10",
        //       featureName: "Notifications and Alerts",
        //       featureDesc:
        //         "    Real-time notifications and alerts to keep users informed about important events and updates.Email, SMS, or in-app notifications.",
        //     },
        //     {
        //       id: "11",
        //       featureName: "Customizable Settings and Preferences",
        //       featureDesc:
        //         "    Options for users to customize their experience and settings according to their needs. Personalization features to enhance user engagement.",
        //     },
        //     {
        //       id: "12",
        //       featureName: "Support and Maintenance",
        //       featureDesc:
        //         "    Ongoing support and maintenance services to ensure the application remains functional and up-to-date. Regular updates and bug fixes to improve performance and security.",
        //     },
        //   ],
        // },
        // {
        //   id: "3",
        //   serviceName: "Customer-Relationship-Management-Systems",
        //   serviceTitle: "Customer Relationship Management (CRM) Systems",
        //   serviceDesc:
        //     " Customer Relationship Management (CRM) Systems are sophisticated web applications designed to help businesses manage and analyze customer interactions throughout the customer lifecycle. They facilitate the organization, automation, and synchronization of sales, marketing, customer service, and support processes. Key features include contact management, sales tracking, lead management, and performance analytics. CRM systems enhance customer relationships by providing a centralized platform for storing customer data, tracking interactions, and optimizing communication, ultimately leading to improved customer satisfaction and business growth.",
        //   features: [
        //     {
        //       id: "1",
        //       featureName: "Contact Management",
        //       featureDesc:
        //         "     Centralized database for storing and managing customer information. Detailed customer profiles with contact details, interaction history, and notes.",
        //     },
        //     {
        //       id: "2",
        //       featureName: "Lead Management",
        //       featureDesc:
        //         "Tools for capturing, tracking, and nurturing leads.Automated lead scoring and assignment",
        //     },
        //     {
        //       id: "3",
        //       featureName: "Sales Pipeline Management",
        //       featureDesc:
        //         "    Visualization of sales pipelines and stages. Tools for tracking deals, forecasting sales, and managing sales processes.",
        //     },
        //     {
        //       id: "4",
        //       featureName: "Task and Activity Management",
        //       featureDesc:
        //         "    Task assignment and tracking for sales and support teams. Calendar integration and activity reminders.",
        //     },
        //     {
        //       id: "5",
        //       featureName: "Email Integration",
        //       featureDesc:
        //         "    Integration with email platforms for sending, receiving, and tracking emails. Email templates and automated email campaigns.",
        //     },
        //     {
        //       id: "6",
        //       featureName: "Marketing Automation",
        //       featureDesc:
        //         "    Tools for creating and managing marketing campaigns. Segmentation and targeting based on customer data.",
        //     },
        //     {
        //       id: "7",
        //       featureName: "Customer Support and Service",
        //       featureDesc:
        //         "    Ticketing system for managing customer inquiries and issues. Knowledge base and self-service portals.",
        //     },
        //     {
        //       id: "8",
        //       featureName: "Analytics and Reporting",
        //       featureDesc:
        //         "    Customizable reports and dashboards for sales, marketing, and customer service. Real-time analytics and performance metrics.",
        //     },
        //     {
        //       id: "9",
        //       featureName: "Mobile Access",
        //       featureDesc:
        //         "    Mobile apps for accessing CRM data and tools on the go. Mobile-friendly interfaces for tablets and smartphones.",
        //     },
        //     {
        //       id: "10",
        //       featureName: "Integration Capabilities",
        //       featureDesc:
        //         "    APIs and connectors for integrating with other business systems (e.g., ERP, e-commerce platforms).Syncing data with email, social media, and other third-party applications.",
        //     },
        //     {
        //       id: "11",
        //       featureName: "Workflow Automation",
        //       featureDesc:
        //         "    Automated workflows for repetitive tasks and processes. Triggers and actions based on specific events or criteria.",
        //     },
        //     {
        //       id: "12",
        //       featureName: "Customization and Scalability",
        //       featureDesc:
        //         "    Customizable fields, layouts, and modules to fit specific business needs. Scalability to grow with the business.",
        //     },
        //     {
        //       id: "13",
        //       featureName: "Customer Segmentation",
        //       featureDesc:
        //         "    Tools for segmenting customers based on demographics, behavior, and purchase history.Targeted marketing and personalized communication.",
        //     },
        //     {
        //       id: "14",
        //       featureName: "Social Media Integration",
        //       featureDesc:
        //         "    Tools for segmenting customers based on demographics, behavior, and purchase history. Targeted marketing and personalized communication.",
        //     },
        //     {
        //       id: "15",
        //       featureName: "Social Media Integration",
        //       featureDesc:
        //         "    Tools for tracking and managing social media interactions. Social listening and engagement features.",
        //     },
        //     {
        //       id: "16",
        //       featureName: "Document Management",
        //       featureDesc:
        //         "     Storage and management of documents related to customers and sales processes.Version control and collaboration features.",
        //     },
        //   ],
        // },
        // {
        //   id: "4",
        //   serviceName: "E-commerce-web-Applications",
        //   serviceTitle: "E-commerce web Applications",
        //   serviceDesc:
        //     "Online stores with product catalogs, shopping carts, payment gateways, and user accounts. Custom solutions for inventory management, order tracking, and customer support.",
        //   features: [
        //     // {
        //     //   id: "1",
        //     //   featureName: "Custom Functionality",
        //     //   featureDesc:
        //     //     "    Tailored features that address specific business processes or user requirements.Unique workflows and tools designed to solve particular problems.",
        //     // },
        //   ],
        // },
        // {
        //   id: "5",
        //   serviceName: "Progressive-Web-Apps",
        //   serviceTitle: "Progressive Web Apps (PWAs)",
        //   serviceDesc:
        //     "    Web applications that offer a native app-like experience. Features like offline access, push notifications, and fast load times.",
        //   features: [
        //     // {
        //     //   id: "1",
        //     //   featureName: "Custom Functionality",
        //     //   featureDesc:
        //     //     "    Tailored features that address specific business processes or user requirements.Unique workflows and tools designed to solve particular problems.",
        //     // },
        //   ],
        // },
        // {
        //   id: "6",
        //   serviceName: "Dashboards-and-Data-Visualization-Tools",
        //   serviceTitle: "Dashboards and Data Visualization Tools",
        //   serviceDesc:
        //     "    Interactive dashboards for monitoring and analyzing data. Custom charts, graphs, and other visualizations using libraries like D3.js or Chart.js.",
        //   features: [
        //     // {
        //     //   id: "1",
        //     //   featureName: "Custom Functionality",
        //     //   featureDesc:
        //     //     "    Tailored features that address specific business processes or user requirements.Unique workflows and tools designed to solve particular problems.",
        //     // },
        //   ],
        // },
        // {
        //   id: "7",
        //   serviceName: "Social-Media-Platforms",
        //   serviceTitle: "Social Media Platforms",
        //   serviceDesc:
        //     "    Applications for social networking, user profiles, messaging, and content sharing. Integration with existing social media APIs and third-party services.",
        //   features: [
        //     // {
        //     //   id: "1",
        //     //   featureName: "Custom Functionality",
        //     //   featureDesc:
        //     //     "    Tailored features that address specific business processes or user requirements.Unique workflows and tools designed to solve particular problems.",
        //     // },
        //   ],
        // },
        // {
        //   id: "8",
        //   serviceName: "Booking-and-Reservation-Systems",
        //   serviceTitle: "Booking and Reservation Systems",
        //   serviceDesc:
        //     "    Applications for booking appointments, reservations, or tickets. Features like calendar integration, availability management, and payment processing.",
        //   features: [
        //     // {
        //     //   id: "1",
        //     //   featureName: "Custom Functionality",
        //     //   featureDesc:
        //     //     "    Tailored features that address specific business processes or user requirements.Unique workflows and tools designed to solve particular problems.",
        //     // },
        //   ],
        // },
        // {
        //   id: "9",
        //   serviceName: "Real-Estate-Platforms",
        //   serviceTitle: "Real Estate Platforms",
        //   serviceDesc:
        //     "Applications for listing and searching properties. Features like property maps, detailed listings, and contact forms",
        //   features: [
        //     // {
        //     //   id: "1",
        //     //   featureName: "Custom Functionality",
        //     //   featureDesc:
        //     //     "    Tailored features that address specific business processes or user requirements.Unique workflows and tools designed to solve particular problems.",
        //     // },
        //   ],
        // },

        // {
        //   id: "3",
        //   serviceName: "commercial-web-site",
        //   serviceTitle: "commercial web site",
        //   serviceDesc:
        //     "Commercial websites are the online presence of businesses with the clear objective of promoting products and enhancing visitor awareness. Their primary goal is to boost sales for the associated brand, and this objective is reflected in both their content and design. Consider Apple's official website as an example.",
        //   features: [
        //     {
        //       id: "1",
        //       featureName: "Clear Navigation",
        //       featureDesc:
        //         "Website navigation should be intuitive, employing a logical site structure and an easy-to-understand menu. When users are confused by the navigation, they’re unlikely to stick around.",
        //     },
        //     {
        //       id: "2",
        //       featureName: "Optimized for Speed",
        //       featureDesc:
        //         "Fast-loading websites benefit both users and search engines, satisfying the demand for immediate content. Make sure you optimize images, enable browser caching – and identify coding issues.",
        //     },
        //     {
        //       id: "3",
        //       featureName: "Responsive Design",
        //       featureDesc:
        //         "As users browse from a range of devices, responsive design is essential. Opt for custom web design that provides a streamlined experience across desktops, smartphones and tablets.",
        //     },
        //     {
        //       id: "4",
        //       featureName: "Call-to-Action",
        //       featureDesc:
        //         "A call-to-action (CTA) directs users to a desired outcome. The most high-converting websites use multiple CTAs to encourage buying products, joining the mailing list, calling for more information and more.",
        //     },
        //     {
        //       id: "5",
        //       featureName: "Built for Users",
        //       featureDesc:
        //         "Unnecessarily complicated websites distract visitors and certainly cause friction in a sales funnel. Carefully consider the user experience, prioritizing attractiveness, readable fonts, white space, search functionality – and a memorable logo.",
        //     },
        //     {
        //       id: "6",
        //       featureName: "Clear Brand Identity",
        //       featureDesc:
        //         "A website is the gateway to the brand. By delivering a consistent experience across all platforms, consumers begin to recognize your brand identity and form a connection with the business.",
        //     },
        //     {
        //       id: "7",
        //       featureName: "High-Quality Content",
        //       featureDesc:
        //         "High-quality, informative content is the foundation of a successful business website. By creating up-to-date, valuable articles, the site will meet the needs of visitors and search engines alike.",
        //     },
        //     {
        //       id: "8",
        //       featureName: "SEO-Friendly Design",
        //       featureDesc:
        //         "SEO and user experience are closely tied, and Google stays committed to giving consumers what they want based on specific searches. Excellent website design will feature effective on-page optimization (including keyword-rich titles and meta descriptions), internal linking and structured data.",
        //     },
        //     {
        //       id: "9",
        //       featureName: "Security Features",
        //       featureDesc:
        //         "Users expect a high level of security when they visit or buy from a website. Protect your business website and users by implementing HTTPS and SSL to encrypt data. Also, use a trusted hosting service and keep all code and software updated.",
        //     },
        //   ],
        // },
      ],
    };
  },
  mutations,
  actions,
  getters,
};
