export default {
  setOldWorks(state, payload) {
    state.oldWorks = payload;
  },
  // setSortedProjects(state, payload) {
  //   state.projects = payload;
  //   console.log("Sorted projects: ", payload);
  // },
  // setProject(state, payload) {
  //   console.log(payload);
  //   state.project_info.id = payload.id;
  //   state.project_info.customer_name = payload.customer_name;
  //   state.project_info.phone_number = payload.phone_number;
  //   state.project_info.city = payload.city;
  //   state.project_info.date = payload.date;
  //   state.project_info.glass_thickness = payload.glass_thickness;
  //   state.project_info.framework_color = payload.framework_color;
  //   state.project_info.glass_color = payload.glass_color;
  //   state.project_info.project_number = payload.project_number;
  //   console.log(payload.id + " project saved in Store succesfully");
  //   console.log("payload number: ", payload.project_number);
  //   console.log("new Proj info: ", state.project_info);
  // },
  // setThicknesses(state, payload) {
  //   state.glassThicknesses = payload;
  // },
  // setGlassTypes(state, payload) {
  //   state.glassTypes = payload;
  // },

  // setglassHardwarecolors(state, payload) {
  //   state.glassHardwarecolors = payload;
  // },
};
