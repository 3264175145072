<template>
    <div class="skill" v-for="skill in skills" :key="skill">
        <h3>
            <p><font-awesome-icon :icon="skill.icon" />
                {{ skill.name }}</p>
            <span>{{ skill.percentage }}%</span>
        </h3>
        <div class="the-progress">
            <span :style="{ width: `${skill.percentage}%` }"></span>
        </div>
    </div>

</template>

<script>

export default {
    props: ['skills'],
}
</script>
<style scoped lang="scss">
.skill {
    h3 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 6px;
        padding-top: 10px;

        span {
            font-size: 12px;
            border: 1px solid #ccc;
            padding: 3px 5px;
            border-radius: 4px;
            color: #41b883;
        }
    }

    .the-progress {
        height: 10px;
        background-color: #eee;
        border-radius: 10px;
        overflow: hidden;

        span {
            display: block;
            height: 100%;
            border-radius: 10px;
            background: linear-gradient(270deg, #6ffbbc 0%, #41b883d8 100%);

        }

        @keyframes animation-progres {
            100% {
                // width:  ;
            }
        }
    }
}
</style>
