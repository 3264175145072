<template>

    <base-spinner v-if="showLoadingDialog"></base-spinner>

    <base-dialog @close="closeDialog" @confifrm="closeDialog" :show="showErrorDialog" title="Error">
        <!-- <p> {{ ErrorMsg }} </p> -->
    </base-dialog>

    <div class="my-works" id="project-cards">

        <div class="card filterDiv show" v-for="work in getOldWorks" :class="work.filterName" :key="work.id">

            <h3>{{ work.projectName }}</h3>
            <a :href="work.url" target="_blank">
                <div class="img-holder">
                    <img :src="work.imgSrc" :alt="work.imgAlt" />
                </div>
            </a>
            <span>{{ work.topic }}</span>
            <p>
                {{ work.description }}
            </p>
            <a class="offer" :href="mailContent">
                <span> Get an offer </span>
                <span>
                    <font-awesome-icon icon="fa-solid fa-arrow-right" />
                </span>
            </a>

        </div>
    </div>
</template>

<script>
export default {
    // props: ['oldWorks'],
    data() {
        return {
            mailContent: `mailto:info@hasanyameen.com?subject=Request For Offer&body=Hello Mr.Hasan ...`,
            // OldWorks:[],
            ErrorMsg: '',
            showErrorDialog: false,
            showLoadingDialog: false,
            // error: null,
        }
    },
    computed: {
        getOldWorks() {
            let works = this.$store.getters["portfolio/getOldWorks"]
            console.log("get OldWorks from DB ...", works);
            return works
        },
    },
    created() {
        this.loadOldWorks();
    },
    methods: {
        // getBrand(brandId) {
        //   var currentUrl = window.location.pathname;
        //   return `${currentUrl}/${brandId}`
        // },
        closeDialog() {
            this.showErrorDialog = false
            this.ErrorMsg = ''
        },
        async loadOldWorks() {
            this.showLoadingDialog = true;
            console.log("load OldWorks from DB ...");

            try {
                await this.$store.dispatch("portfolio/loadOldWorks");
            }
            catch (error) {
                this.showErrorDialog = true
                this.ErrorMsg = error.message || "Something went wrong while loading old works from DB!";
                // console.log("this.ErrorMsg", this.ErrorMsg);
            }
            this.showLoadingDialog = false;
        },
    },
}
</script>

<style lang="scss" scoped>
@import '../../scss/vars';

.my-works {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    margin-left: 20px;
    margin-right: 20px;
    overflow: hidden;
    padding-bottom: 20px;

    @media (max-width: 567px) {
        grid-template-columns: minmax(200px, 1fr);
        gap: 10px;
    }

    .card {
        padding: 0;
        border: 1px dotted $bg-clr-2;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        display: none;
        overflow: hidden;
        position: relative;

        &.show {
            display: block;
        }

        h3 {
            margin-bottom: 5px;
            padding: 15px;
            min-height: 60px;
            text-transform: uppercase;
            font-weight: bold;
            background-color: $bg-clr-2;

        }

        a {

            .img-holder {
                position: relative;
                width: 80%;
                margin: auto;
                // border: 1px solid red;
                overflow: hidden;

                img {
                    width: 100%;
                    aspect-ratio: 1/1;
                    object-fit: contain;
                    opacity: 0.7;
                    cursor: pointer;
                    border-bottom: 1px dotted $bg-clr-2;

                }
            }

        }


        &:hover {
            border: 1px solid $bg-clr-2;

            .img-holder {
                img {
                    opacity: 1;
                }
            }
        }

        >span {
            display: block;
            padding-left: 15px;
            text-align: left;
            text-transform: capitalize;
            font-size: 10px;
            color: $fnt-clr-4;
            line-height: 20px;
            margin-top: 2px;
            background-color: $bg-clr-5;
        }

        p {
            display: block;
            text-align: left;
            padding-left: 15px;
            line-height: 25px;
            margin-bottom: 35px;
        }

        .offer {
            display: flex;
            justify-content: space-around;
            align-items: center;
            text-decoration: none;

            width: 100%;
            padding: 5px 10px;
            background-color: $bg-clr-5;
            position: absolute;
            bottom: 0;


            span {
                color: $fnt-clr-4;
                font-size: 18px;

                &:last-child {
                    animation-name: animation-offer;
                    animation-duration: 1s;
                    animation-iteration-count: infinite;
                    animation-timing-function: linear;
                    animation-direction: alternate;
                }

                @keyframes animation-offer {
                    100% {
                        transform: translateX(20px);
                    }

                }
            }

        }
    }
}
</style>