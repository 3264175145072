export default {
  // SideLeft getters
  getOldWorks(state) {
    return state.oldWorks;
    // saveTasks()
  },
};

/* [2] 'POST' save new task to firebase db */
// async function saveTasks() {
//   let url = `https://hasanyameen-com-default-rtdb.firebaseio.com/oldWorks.json`;
//   const response = await fetch(url, {
//     method: "POST",
//     body: JSON.stringify( {
//       id: "5",
//       projectName: "one-page personal portfolio",
//       topic: "personal page",
//       filterName: "personal",
//       imgSrc: require("../../../../public/one-page-portfolio.png"),
//       imgAlt: "Social page template",
//       url: "https://hasan-yamin.github.io/Template3-html-css-/#features",
//       description:
//         "a one-page portfolio paage, using this page you can show your portfolio to more pepole arround the world\n HTML5,CSS3,pure JS,JSON",
//     }),
//   });
//   const responseData = await response.json();
//   if (!response.ok) {
//     console.log(responseData);
//     const error = new Error(responseData.message || "failed to authenticate");
//     throw error;
//   }
// }
