import { URL } from "../../store_ver";
// import { xdb } from "../../../xdb";
// import { getRequest, postRequest, putRequest, deleteRequest } from "../../requests";
export default {
  // ! GET REQUEST FUNCTIONS
  async loadOldWorks(context, payload) {
    let url = `${URL}oldWorks.json`;
    const response = await fetch(url);
    // console.log(response);
    const responseData = await response.json();
    // console.log("responseData: ");
    // console.log(responseData);
    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to fetch!");
      console.log(error);
      throw error;
    }
    let oldWork;
    // loop on all tasks came from db
    let oldWorks = [];
    for (oldWork in responseData) {
      // add task to allTasks array
      oldWorks.push(responseData[oldWork]);
    }
    // console.log(oldWorks);
    context.commit("setOldWorks", oldWorks);
  },
  // async loadProjects(context, payload) {
  //   console.log("****** Start Loading Projects ******");
  //   const tokenAuth = `Token ${localStorage.getItem("token")}`;
  //   const Url = url + `${payload}`;
  //   try {
  //     const response = await getRequest(Url, tokenAuth);
  //     context.commit("setProjects", {
  //       projects: response.results,
  //       projectsNumber: response.count,
  //       nextProject: response.next,
  //       previousProject: response.previous,
  //     });
  //   } catch (error) {
  //     throw error;
  //   }
  // },
  // async searchProjects(context, payload) {
  //   console.log("****** Start searching Projects ******");
  //   const tokenAuth = `Token ${localStorage.getItem("token")}`;
  //   const Url = url + `projects/?search=${payload}`;
  //   try {
  //     const response = await getRequest(Url, tokenAuth);
  //     context.commit("setProjects", {
  //       projects: response.results,
  //       projectsNumber: response.count,
  //       nextProject: response.next,
  //       previousProject: response.previous,
  //     });
  //   } catch (error) {
  //     throw error;
  //   }
  // },
  // async loadGlassThecknesses(context, payload) {
  //   console.log("****** Load Glass Thecknesses ******");
  //   const tokenAuth = `Token ${localStorage.getItem("token")}`;
  //   const Url = url + `glass-thicknesses/`;
  //   try {
  //     const response = await getRequest(Url, tokenAuth);
  //     context.commit("setThicknesses", response.results);
  //   } catch (error) {
  //     throw error;
  //   }
  // },
  // async loadGlassTypes(context, payload) {
  //   console.log("****** Load Glass Types ******");
  //   const tokenAuth = `Token ${localStorage.getItem("token")}`;
  //   const Url = url + `glass-types/`;
  //   try {
  //     const response = await getRequest(Url, tokenAuth);
  //     context.commit("setGlassTypes", response.results);
  //   } catch (error) {
  //     throw error;
  //   }
  // },
  // async loadGlassHardwarecolors(context, payload) {
  //   console.log("****** load Glass Hardware }olors ******");
  //   const tokenAuth = `Token ${localStorage.getItem("token")}`;
  //   const Url = url + `hardware-colors/`;
  //   try {
  //     const response = await getRequest(Url, tokenAuth);
  //     context.commit("setglassHardwarecolors", response.results);
  //   } catch (error) {
  //     throw error;
  //   }
  // },
  // // ! POST REQUEST FUNCTIONS
  // async createProject(context, payload) {
  //   console.log("****** Create Project ******");
  //   const tokenAuth = `Token ${localStorage.getItem("token")}`;
  //   const Url = url + `projects/`;
  //   const newProject = {
  //     customer_name: payload.customer_name,
  //     phone_number: payload.phone_number,
  //     city: payload.city,
  //     glass_thickness: payload.glass_thickness,
  //     framework_color: payload.framework_color,
  //     glass_color: payload.glass_color,
  //     project_number: payload.project_number,
  //   };
  //   try {
  //     const response = await postRequest(Url, tokenAuth, newProject);
  //     context.commit("setProject", response);
  //     return response.id;
  //   } catch (error) {
  //     throw error;
  //   }
  // },
  // async createFirstRevision(context, payload) {
  //   console.log("****** Create First Revision ******");
  //   const tokenAuth = `Token ${localStorage.getItem("token")}`;
  //   const Url = url + "revisions/";
  //   //XXX - reset the xdb content, if we are working on a design and we created another one
  //   // then the new design will clone the current project which is incorrect.
  //   // We need to reset the XDB whenever we create the first commmit
  //   xdb.resetXDB();
  //   const newRevision = {
  //     design: JSON.stringify(xdb.getShowerDesign().toJSONObj()),
  //     project: payload.projectID,
  //   };
  //   try {
  //     await postRequest(Url, tokenAuth, newRevision);
  //   } catch (error) {
  //     console.log(error);
  //     throw error;
  //   }
  // },
  // // ! DELETE REQUEST FUNCTIONS
  // async deleteProject(context, payload) {
  //   console.log("****** Deleting Project ******");
  //   const tokenAuth = `Token ${localStorage.getItem("token")}`;
  //   const Url = url + `projects/${payload}`;
  //   try {
  //     await deleteRequest(Url, tokenAuth);
  //     console.log("project " + payload + " was deleted");
  //   } catch (error) {
  //     console.log("error from action ", error.message);
  //     throw error;
  //   }
  // },
  // // ! PUT REQUEST FUNCTIONS
  // async updateProject(context, payload) {
  //   console.log("****** Update Project ******");
  //   const tokenAuth = `Token ${localStorage.getItem("token")}`;
  //   const Url = url + `projects/${payload.id}/`;
  //   const newProject = {
  //     customer_name: payload.customer_name,
  //     phone_number: payload.phone_number,
  //     city: payload.city,
  //     glass_thickness: payload.glass_thickness,
  //     framework_color: payload.framework_color,
  //     glass_color: payload.glass_color,
  //     project_number: payload.project_number,
  //   };
  //   try {
  //     const response = await putRequest(Url, tokenAuth, newProject);
  //   } catch (error) {
  //     throw error;
  //   }
  // },
  // // ! NO REQUEST FUNCTIONS
  // saveProjectInStore(context, payload) {
  //   context.commit("setProject", payload);
  // },
  // setSortedProjects(context, payload) {
  //   context.commit("setSortedProjects", payload);
  // },
};
