<template>
    <div class="news">
        <h2>New Features</h2>
        <div class="news-card" v-for="news in getNewsList" :key="news.id">
            <h3 class="title">{{ news.title }}</h3>
            <span class="news-detail">{{ news.detail }}</span>
        </div>
    </div>

</template>

<script>
export default {
    computed: {
        getNewsList() {
            return this.$store.getters["home/getNewsList"]
        },
    },
}
</script>

<style scoped lang="scss">
@import '../../scss/vars';

.news {
    width: calc(100% - 5px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 15px auto;
    box-shadow: $bg-clr-2 0px 3px 6px, $bg-clr-2 0px 3px 6px;
    padding: 20px 10px;
    border-radius: 10px;

    @media (max-width: 576px) {
        display: none;
    }

    h2 {
        font-size: 20px;
    }

    .news-card {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 5px;

        .title {
            width: 100%;
            text-align: start;
            padding-left: 10px;
            line-height: 25px;
            text-transform: uppercase;
            font-weight: bold;
            background-color: $bg-clr-3;
        }

        .news-detail {
            font-size: 16px;
            color: $fnt-clr-1;
            line-height: 20px;
            letter-spacing: 1px;

        }
    }
}
</style>