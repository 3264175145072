export default {
  // SideLeft getters
  getPersonalInfo(state) {
    return state.personalInfo;
  },
  getLinks(state) {
    return state.links;
  },
  getNewsList(state) {
    return state.newsList;
  },

  // SideRight getters

  getSummaries(state) {
    return state.summaries;
  },
  getSkills(state) {
    return state.skills;
  },
  getOtherSkills(state) {
    return state.otherSkills;
  },
  getExperiences(state) {
    return state.experiences;
  },
  getEducations(state) {
    return state.educations;
  },
  getLanguages(state) {
    return state.languages;
  },
};
