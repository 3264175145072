<template>
  <h1 class="main-title">
    <slot></slot>
  </h1>

</template>
<style scoped lang="scss">
@import '../scss/vars';

.main-title {
  margin: 0 auto 20px;
  // border: 2px solid #000;
  padding: 5px 10px;
  font-size: 25px;
  width: fit-content;
  position: relative;
  z-index: 1;
  transition: 0.3s;
  text-transform: uppercase;
  color: $main-color;

  @media (max-width: 767px) {
    margin: 0 auto 10px;
    // border: 2px solid #000;
    padding: 5px 10px;
    font-size: 20px;
  }

  &:hover {
    color: #fff;
    border: 2px solid transparent;
    transition-delay: 0.4s;

    &::before {
      z-index: -1;
      animation: left-move 0.5s linear 1 forwards;
    }

    &::after {
      z-index: -1;
      animation: right-move 0.5s linear 1 forwards;
    }
  }

  &::before,
  &::after {
    content: '';
    width: 12px;
    height: 12px;
    background-color: $main-color;
    position: absolute;
    border-radius: 50%;
    top: 50%;
    transform: translateY(-50%);
  }

  &::before {
    left: -30px;
  }

  &::after {
    right: -30px;
  }
}
</style>
