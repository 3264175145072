<template>
  <div class="base-container">
    <slot></slot>
  </div>
</template>
<style scoped lang="scss">
@import '../../scss/vars';

.base-container {
  width: 100%;
  min-height: 100vh;
  margin: auto;
  background-color: $bg-clr-4;
  // padding-left: 50px;
  // padding-right: 50px;
}
</style>
