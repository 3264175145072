<template>
  <div class="mover"><span></span></div>
</template>

<style lang="scss">
@import '../scss/vars';

.mover {
  width: 90px;
  height: 5px;
  margin-left: calc(50%);
  transform: translateX(-50%);
  margin-bottom: 20px;
  margin-top: 20px;
  background-color: $main-alt-color;
  display: flex;
  align-items: center;
  border-radius: 5px;

  span {
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: $main-color;
    animation-name: animation-mover;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-direction: alternate;

  }
}

@keyframes animation-mover {
  100% {
    transform: translateX(90px);
  }
}
</style>