<template>
  <main-header />
  <base-container>
    <router-view />
  </base-container>
  <the-footer />
</template>
<script>
import MainHeader from './assets/MainHeader.vue'
import TheFooter from './assets/TheFooter.vue'

export default {
  components: {
    MainHeader, TheFooter,
  },
}
</script>
<style lang="scss">
@import './scss/global_rules';
</style>