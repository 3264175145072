// import { xdb } from "../xdb";
// import { ShowerDesign } from "../modules";
// import { vars } from "../config";
// import { saveHistory } from "../history";
// import { url } from "./store_ver";
// import { getRequest, postRequest } from "./requests";
//modules
// import authModule from "./modules/auth/index.js";
import homeModule from "./modules/home/index.js";
import portfolioModule from "./modules/portfolio/index.js";
import servicesModule from "./modules/services/index.js";
// import showerDesignModule from "./modules/projects/index.js";

export default {
  modules: {
    // authentication: authModule,
    home: homeModule,
    portfolio: portfolioModule,
    services: servicesModule,
  },
  state() {
    return {
      mailContent: `mailto:info@hasanyameen.com?subject=Request For Offer&body=Hello Mr.Hasan ...`,
    };
  },
  mutations: {
    // setPartToView(state, payload) {
    //   state.selectedPartLabel = payload;
    //   xdb.setSelectedPartLabel(payload);
    //   // XXX - akram: we need to refresh all Glass sides in the top view to remove the focus effect from other sides
    //   if (payload[0] === "b") {
    //     // XXX - akram: we need to render the side that contains the on-focus part after other glass sides since side view is connected to partChanged event
    //     if (state.showerDesign.getLeftSide().getType() === "Glass") {
    //       state.showerDesign.getLeftSide().emitPartChanged();
    //     }
    //     if (state.showerDesign.getRightSide().getType() === "Glass") {
    //       state.showerDesign.getRightSide().emitPartChanged();
    //     }
    //     if (state.showerDesign.getBottomSide().getType() === "Glass") {
    //       state.showerDesign.getBottomSide().emitPartChanged();
    //     }
    //     state.showerDesign.getBottomSide().emitPartChanged();
    //   } else if (payload[0] === "l") {
    //     if (state.showerDesign.getBottomSide().getType() === "Glass") {
    //       state.showerDesign.getBottomSide().emitPartChanged();
    //     }
    //     if (state.showerDesign.getRightSide().getType() === "Glass") {
    //       state.showerDesign.getRightSide().emitPartChanged();
    //     }
    //     state.showerDesign.getLeftSide().emitPartChanged();
    //   } else if (payload[0] === "r") {
    //     if (state.showerDesign.getBottomSide().getType() === "Glass") {
    //       state.showerDesign.getBottomSide().emitPartChanged();
    //     }
    //     if (state.showerDesign.getLeftSide().getType() === "Glass") {
    //       state.showerDesign.getLeftSide().emitPartChanged();
    //     }
    //     if (state.showerDesign.getRightSide().getType() === "Glass") {
    //       state.showerDesign.getRightSide().emitPartChanged();
    //     }
    //   }
    // },
  },

  actions: {
    // ! POST REQUEST FUNCTIONS
    // async save(context, projectId) {
    //   console.log("****** Saving ******");
    //   const tokenAuth = `Token ${localStorage.getItem("token")}`;
    //   const Url = url + "revisions/";
    //   const newRevision = {
    //     design: JSON.stringify(this.state.showerDesign.toJSONObj()),
    //     project: projectId,
    //   };
    //   try {
    //     const response = await postRequest(Url, tokenAuth, newRevision);
    //     console.log(
    //       "....... response Data from save ........",
    //       JSON.parse(response.design)
    //     );
    //     console.log("....... New Revision saved ........");
    //   } catch (error) {
    //     console.log(error);
    //     throw error;
    //   }
    // },
    // setPartHeight(context, value) {
    //   context.commit("setPartHeight", value);
    // },
  },

  getters: {
    getMailContent(state) {
      return state.mailContent;
    },
  },
};
