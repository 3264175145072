<template>
  <div class="header">
    <div class="logo">
      <img src="../../public/HYlogo.png" alt="HY PROGRAMMING logo" />
    </div>
    <!-- <div class="date">{{  new Date().toLocaleString()}}</div> -->
    <nav>
      <ul>
        <li><router-link to="/">Home</router-link></li>
        <li><router-link to="/portfolio">Portfolio</router-link></li>
        <li><router-link to="/services">Services</router-link></li>
        <!-- <li><router-link to="/about">About</router-link></li> -->
        <!-- <li><router-link to="/blog">Blog</router-link></li> -->
        <!-- <li><router-link to="/hireme">Hire Me</router-link></li> -->
        <!-- <li><router-link to="/profile">Profile</router-link></li> -->
      </ul>
    </nav>
  </div>
</template>
<style scoped lang="scss">
@import '../scss/vars';

.header {
  width: 100%;
  height: 60px;
  background-color: $bg-clr-1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 50px;

  @media (max-width: 567px) {
    height: 50px;
    padding-left: 10px;
    padding-right: 30px;
  }

  .logo {
    width: 60px;
    height: 60px;
    // margin-left: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    // border: 1px solid;

    @media (max-width: 567px) {
      width: 50px;
      height: 50px;
    }

    img {
      width: 50px;

      &:hover {
        transform: scale(1.15);
      }

      @media (max-width: 567px) {
        width: 40px;
        height: 40px;
      }
    }
  }

  nav {
    height: 60px;
    width: fit-content;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;

    ul {
      list-style: none;
      height: inherit;
      display: flex;
      align-items: center;
      gap: 10px;

      li {
        @media (max-width: 567px) {
          font-size: 12px;
        }

        a {
          color: $fnt-clr-1;
          text-decoration: none;
          text-transform: uppercase;

          &.router-link-exact-active {
            color: $fnt-clr-4-1;
            position: relative;

            &::after {
              position: absolute;
              content: '';
              width: 100%;
              height: 1.5px;
              left: 50%;
              bottom: -5px;
              transform: translateX(-50%);
              background-color: $bg-clr-2-2;
            }
          }

          &:hover {
            color: $fnt-clr-4-1;
          }
        }
      }
    }
  }
}
</style>
