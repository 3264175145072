<template>
  <div class="side-right-container">
    <section class="summary">
      <p v-for="summary in getSummaries" :key="summary.id">{{ summary.description }}</p>
    </section>
    <my-skills />
    <!-- <wave-devider></wave-devider> -->
    <base-container>
      <section-devider><font-awesome-icon :icon="['fas', 'clock-rotate-left']" /></section-devider>

      <section class="graph experience">
        <ul>
          <li v-for="experience in getExperiences" :key="experience.id">
            <div class="work-card">
              <h3 class="job-title">{{ experience.jobTitle }}</h3>
              <span class="company-name">{{ experience.companyName }} - {{ experience.companyAddress }}</span>
              <span class="work-period">{{ experience.workPeriod }}</span>
              <span class="job-description" :style="{ display: showDescription }">{{ experience.jobDescription
                }}</span>
              <div class="job-skills">
                <span class="job-skill" v-for="skill in experience.skills" :key="skill">{{ skill }}</span>
              </div>
            </div>
          </li>
        </ul>
      </section>

      <section-devider><font-awesome-icon icon="fa-solid fa-graduation-cap" /></section-devider>
      <section class="graph education">
        <ul>
          <li v-for="education in getEducations" :key="education.id">
            <div class="education-card">
              <div class="card-inner">
                <div class="card-front">
                  <p>{{ education.university }}</p>
                </div>
                <div class="card-back">
                  <p>{{ education.degree }}</p>
                  <span>{{ education.year }} </span>
                </div>
              </div>
            </div>
          </li>

        </ul>
      </section>

      <section-devider><font-awesome-icon :icon="['fas', 'language']" /></section-devider>
      <section class="language">
        <table class="language-list">
          <tr>
            <th>language</th>
            <th>Read</th>
            <th>Write</th>
            <th>Speak</th>
          </tr>
          <tr v-for="language in getLanguages" :key="language.id">
            <td>{{ language.name }}</td>
            <td>
              <h3>
                <span>{{ language.read }}%</span>
              </h3>
              <div class="the-progress">
                <span :style="{ width: `${language.read}%` }"></span>
              </div>
            </td>
            <td>
              <h3>
                <span>{{ language.write }}%</span>
              </h3>
              <div class="the-progress">
                <span :style="{ width: `${language.write}%` }"></span>
              </div>
            </td>
            <td>
              <h3>
                <span>{{ language.speak }}%</span>
              </h3>
              <div class="the-progress">
                <span :style="{ width: `${language.speak}%` }"></span>
              </div>
            </td>
          </tr>
        </table>
      </section>
    </base-container>
  </div>
</template>

<script>
import MySkills from './MySkills.vue'
export default {
  components: {
    MySkills,
  },
  data() {
    return {
      showDescription: 'block',
    }
  },
  computed: {
    getSummaries() {
      return this.$store.getters["home/getSummaries"]
    },
    getExperiences() {
      return this.$store.getters["home/getExperiences"]
    },
    getEducations() {
      return this.$store.getters["home/getEducations"]
    },
    getLanguages() {
      return this.$store.getters["home/getLanguages"]
    },
  },
}
</script>

<style lang="scss">
@import '../../scss/vars';

.side-right-container {
  width: 100%;
  overflow: hidden;


  .summary {
    width: 90%;
    overflow: hidden;
    background-color: $bg-clr-1;
    border-radius: 15px;
    padding: 20px 30px 40px 30px;

    margin: 20px auto;

    @media (max-width: 576px) {
      width: 100%;
      padding: 20px 20px 40px 20px;

    }

    h1 {
      margin-bottom: 20px;
      text-transform: capitalize;

      @media (max-width: 576px) {
        display: none;
      }
    }

    p {
      width: 100%;
      text-transform: capitalize;
      text-align: justify;
      line-height: 30px;
      letter-spacing: 1px;
      font-family: Georgia, serif;
      margin-bottom: 20px;

      &::first-letter {
        text-transform: uppercase;
        font-size: 2em;

      }


      @media (max-width: 576px) {
        line-height: 20px;
        letter-spacing: 0.8px;
        font-size: 15px;
      }
    }
  }

  // .jobs {

  //   margin-top: 90px;
  //   display: flex;
  //   flex-direction: row;
  //   justify-content: center;
  //   align-items: center;
  //   gap: 20px;
  //   width: 100%;

  //   @media (max-width: 767px) {
  //     flex-direction: column;
  //     margin-top: 20px;

  //   }

  //   .card {
  //     width: 400px;
  //     height: 300px;
  //     background-color: $bg-clr-2-1;
  //     display: flex;
  //     flex-direction: column;
  //     justify-content: center;
  //     align-items: center;
  //     gap: 20px;
  //     position: relative;
  //     border-radius: 15px;

  //     @media (max-width: 767px) {
  //       width: 300px;
  //       height: 200px;
  //     }

  //     .title {
  //       a {
  //         text-decoration: none;
  //         color: #000;
  //         padding: 0 0 10px;
  //         text-transform: capitalize;
  //       }
  //     }

  //     .company-name {
  //       display: block;
  //       font-size: medium;
  //       color: #776f6f;
  //       text-transform: uppercase;
  //       padding: 10px;
  //     }

  //     //&::before,
  //     &::after {
  //       position: absolute;
  //       content: "";
  //       width: 20%;
  //       height: 20%;
  //       background-color: white;
  //       transition: all 2s;
  //     }

  //     &:first-child {


  //       @media (max-width: 767px) {
  //         &::before {
  //           bottom: 0;
  //           left: 0;
  //           border-radius: 0 100% 0 15px;
  //         }
  //       }

  //       @media (min-width: 768px) {
  //         &::before {
  //           top: 0;
  //           left: 0;
  //           border-radius: 15px 0 100% 0;
  //         }
  //       }

  //       &::after {
  //         bottom: 0;
  //         right: 0;
  //         border-radius: 100% 0 15px 0;
  //       }
  //     }

  //     &:last-child {
  //       &::before {
  //         top: 0;
  //         right: 0;
  //         border-radius: 0 15px 0 100%;
  //       }

  //       &::after {
  //         @media (max-width: 767px) {
  //           // top: 0;
  //           // left: 0;
  //           // border-radius: 15px 0 100% 0;
  //           top: 0;
  //           right: 0;
  //           border-radius: 0 15px 0 100%;
  //         }

  //         @media (min-width: 768px) {
  //           bottom: 0;
  //           left: 0;
  //           border-radius: 0 100% 0 15px;
  //         }
  //       }


  //     }

  //     // &:hover::before,
  //     &:hover:after {
  //       background-color: $bg-clr-active;
  //       color: #fff;
  //       width: 96%;
  //       height: 96%;
  //       border-radius: 15px;
  //       transition: all 1s;
  //       content: attr(hover-content);
  //       padding: 2%;
  //       text-transform: capitalize;
  //       display: flex;
  //       align-items: center;
  //       justify-content: center;
  //       line-height: 25px;
  //       letter-spacing: 1px;
  //       box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  //     }

  //   }
  // }

  .graph {
    margin: auto;

    @media (max-width: 576px) {
      width: 90%;
    }

    ul {
      width: 100%;
      display: flex;
      flex-direction: column;
      list-style: none;

      li {
        position: relative;
        width: 50%;
        padding: 0 40px;

        @media (max-width: 767px) {
          width: 100%;
          padding: 20px;
          // box-shadow: $bg-clr-2-1 0px -50px 36px -28px inset;

        }

        .work-card {
          // padding: 20px;
          box-shadow: 0px 0px 15px #F9F9F9;
          border: 1px solid $bg-clr-2-1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          gap: 5px;
          border-radius: 10px;

          @media (max-width: 767px) {
            text-align: left;
            border: none;
            box-shadow: $bg-clr-2-1 0px -50px 36px -28px inset;
          }

          .job-title {
            width: 100%;
            font-size: 16px;
            font-weight: bold;
            text-transform: capitalize;
            padding: 10px;
            background-color: $bg-clr-2-1;
            border-radius: 10px 10px 0 0;

            @media (max-width: 767px) {
              font-size: 14px;
            }

          }

          .company-name {
            font-size: 14px;
            text-transform: uppercase;
            padding: 10px;
            text-align: left;

            @media (max-width: 767px) {
              font-size: 13px;
            }
          }

          .work-period {
            font-size: 14px;
            color: $bg-clr-2-1;
            padding: 10px;

            @media (max-width: 767px) {
              font-size: 13px;
            }
          }

          .company-address {
            font-size: 14px;
            padding: 10px;

            @media (max-width: 767px) {
              font-size: 13px;
            }
          }

          .job-description {
            font-size: 12px;
            color: #2e2d2d;
            line-height: 15px;
            text-align: justify;
            padding: 0px;
            height: 0px;
            overflow: hidden;
            transition: all 1s;

            @media (max-width: 767px) {
              font-size: 12px;
            }
          }


          .job-skills {
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 2px;
            justify-content: center;
            background-color: $bg-clr-2-1;
            border-radius: 0 0 10px 10px;
            height: 0px;
            overflow: hidden;

            .job-skill {
              padding: 10px;
              text-transform: capitalize;
              font-size: 12px;
              background-color: #fff;
              border: none;
              border-radius: 5px;

              &:hover {
                background-color: $bg-clr-active;
                color: #FFF
              }

              @media (max-width: 767px) {
                font-size: 10px;
                padding: 5px;

              }
            }
          }

          &:hover {

            .job-description {
              height: auto;
              padding: 10px;
              transition: 1s linear;
            }

            .job-skills {
              animation: showskills 200ms ease-in-out forwards;

            }
          }
        }

        .education-card {
          width: 300px;
          height: 200px;
          perspective: 1000px;

          .card-inner {
            width: 100%;
            height: 100%;
            position: relative;
            transform-style: preserve-3d;
            transition: transform 0.999s;

            .card-front,
            .card-back {
              padding: 20px 20px;
              position: absolute;
              width: 100%;
              height: 100%;
              backface-visibility: hidden;
              font-size: 20px;
              border-radius: 10px;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              letter-spacing: 0.8px;

              @media (max-width: 767px) {
                font-size: 16px;
              }
            }

            .card-front {
              background-color: $bg-clr-2-1;
              transform: rotateY(0deg);
            }

            .card-back {
              background-color: $bg-clr-active;
              transform: rotateY(180deg);

              span {
                margin-top: 20px;
                color: #FFF;
              }
            }
          }

          &:hover .card-inner {
            transform: rotateY(-180deg);
          }


        }

        &::before {
          position: absolute;
          content: '';
          top: 50%;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background-color: $bg-clr-active;
          border: 5px solid #eee;
          z-index: 5;
        }

        &::after {
          position: absolute;
          content: '';
          top: 0;
          width: 2px;
          height: 100%;
          background-color: $bg-clr-active;
          background-color: #eee;
        }

        &:nth-child(odd) {
          align-self: flex-end;

          &::before {
            left: -15px;

          }

          &::after {
            left: -1px;
          }
        }

        &:nth-child(even) {
          align-self: flex-start;

          >div {
            float: right;

            @media (max-width: 767px) {
              float: left;
            }
          }

          &::before {
            right: -15px;

            @media (max-width: 767px) {
              left: -15px;
            }
          }

          &::after {
            right: -1px;

            @media (max-width: 767px) {
              left: -1px;
            }
          }
        }
      }
    }
  }

  .language {
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 5px;


    .language-list {
      font-family: Arial, Helvetica, sans-serif;
      border-collapse: collapse;
      width: 80%;

      @media (max-width: 767px) {
        width: 100%;
      }

      tr:nth-child(even) {
        background-color: #f2f2f2;
      }

      tr:hover {
        background-color: #ddd;
      }

      td,
      th {
        border: 1px solid #ddd;
        padding: 8px;
        text-transform: capitalize;
        width: 25%;
      }

      th {
        padding-top: 12px;
        padding-bottom: 12px;
        text-align: center;
        background-color: $bg-clr-2;
        color: white;
      }

      td {
        h3 {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-bottom: 6px;
          padding-top: 15px;

          span {
            font-size: 12px;
            border: 1px solid #ccc;
            padding: 3px 5px;
            border-radius: 4px;
            color: $bg-clr-active;
          }
        }

        .the-progress {
          height: 10px;
          background-color: #eee;
          border-radius: 10px;
          overflow: hidden;

          span {
            display: block;
            height: 100%;
            border-radius: 10px;
            background: linear-gradient(270deg, $bg-clr-active 0%, $bg-clr-2-1 100%);

          }

          @keyframes animation-progres {
            100% {
              // width:  ;
            }

          }
        }
      }
    }
  }

  @keyframes showskills {
    0% {
      height: 0px;
    }

    100% {
      height: auto;
      padding: 5px;
      margin-top: 10px;
    }
  }
}
</style>
