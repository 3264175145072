<template>
  <div class="service" v-for="(service, index) in getServices" :key="service.id">
    <div class="get-offer">
      <a :href="getMailContent">Get an Offer</a>
    </div>
    <div class="num">
      <span>
        {{ index + 1 }}
      </span>
      <router-link :to="serviceDetailsLink(service.serviceName)">
        <h4>{{ service.serviceTitle }}</h4>
      </router-link>
    </div>
    <p v-if="service.serviceDesc">{{ service.serviceDesc }}</p>
    <!-- <p v-if="service.benefits">
      <span>Benifits:</span>
      {{ service.benefits }}
    </p>
    <p v-if="service.note">
      <span>Note:</span>
      {{ service.note }}
    </p> -->
  </div>
</template>
<script>
export default {
  methods: {
    serviceDetailsLink(link) {
      return this.$route.path + '/' + link //this.serviceName // /coaches/c1
    },
  },
  computed: {
    getServices() {
      return this.$store.getters["services/getServices"]
    },
    getMailContent() {
      return this.$store.getters["getMailContent"]
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../scss/vars';

.service {
  padding: 15px;
  // border: 2px solid #eae3e3;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: $bg-clr-1;

  &:hover {
    transform: translateY(-8px);
    border: none;
    box-shadow: 0 0 10px 5px #f4f4f4;
    transition: 0.5s;
  }

  .num {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    span {
      display: inline-block;
      width: 50px;
      height: 50px;
      background-color: #41b8836d;
      border-radius: 50%;
      line-height: 52px;
      font-size: 50px;
      color: #fff;

      @media (max-width: 767px) {
        width: 40px;
        height: 40px;
        line-height: 40px;
        font-size: 30px;
      }
    }

    h4 {
      margin-left: 20px;
      padding-bottom: 5px;
      text-transform: capitalize;
      line-height: 22px;
      font-size: 18px;
      letter-spacing: 2px;
      font-weight: bold;
      border-bottom: 2px solid #eae3e3;

      @media (max-width: 767px) {
        line-height: 18px;
        font-size: 15px;
      }
    }
  }

  p {
    line-height: 24px;
    font-size: 16px;
    text-align: left; 
    text-transform: capitalize;

    span {
      font-weight: bold;
    }

    @media (max-width: 767px) {
      line-height: 18px;
      font-size: 13px;
      // margin-bottom: 15px;
    }
  }

  >span {
    display: inline-block;
    text-decoration: none;
    line-height: 24px;
    font-size: 14px;
    color: $fnt-clr-4-1;
    text-transform: uppercase;
    letter-spacing: 2px;
    cursor: pointer;

    @media (max-width: 767px) {
      line-height: 18px;
      font-size: 12px;
      color: $fnt-clr-4-1;
      letter-spacing: 2px;
    }
  }

  .get-offer {
    position: absolute;
    top: -30px;
    right: -30px;
    width: 70px;
    height: 70px;
    background-color: $bg-clr-2-2;
    border: 2px dotted $fnt-clr-4-1;
    border-start-start-radius: 50%;
    border-start-end-radius: 50%;
    border-end-start-radius: 10%;
    border-end-end-radius: 50%;
    padding: 10px;
    font-size: 14px;
    font-weight: bold;
    z-index: 3;
    transition: 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 767px) {
      top: -20px;
      right: -15px;
      width: 60px;
      height: 60px;
      padding: 9px;
      font-size: 12px;
    }

    a { 
      text-decoration: none; 
      color: $fnt-clr-2; 
    }
  }  
  &:hover .get-offer {
    width: 90px;
    height: 90px;
    padding-top: 20px;
    font-size: 16px;
    background-color: #41b883;
    cursor: pointer;

    @media (max-width: 767px) {
      width: 70px;
      height: 70px;
      padding-top: 10px;
      font-size: 14px;
    }
  }


}
</style>
