<template>
  <section class="home-view">
    <aside class="side-left">
      <side-left />
    </aside>
    <aside class="side-right">
      <side-right />
    </aside>
  </section>
</template>

<script>
import SideLeft from '@/components/home/SideLeft.vue'
import SideRight from '@/components/home/SideRight.vue'

export default {
  // mounted() {
  //   // document.title = 'Hasan Yameen - Home';
  // },
  components: {
    SideLeft,
    SideRight,
  },

}
</script>

<style lang="scss">
@import '../scss/vars';

.home-view {
  width: 95%;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  margin: auto;
  padding-top: 20px;

  @media (max-width: 567px) {
    flex-direction: column;

  }

  .side-left {
    width: 30%;
    overflow: hidden;
    border-radius: 10px;

    @media (max-width: 567px) {
      width: 100%;
    }
  }

  .side-right {
    width: 70%;
    overflow: hidden;

    @media (max-width: 567px) {
      width: 100%;
    }
  }
}
</style>
