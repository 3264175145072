import { createApp } from 'vue'
import App from './App.vue'
const app = createApp(App);
import router from './router'
app.use(router)
// import store from './store'
// app.use(store)


import BaseContainer from "./assets/containers/BaseContainer.vue";
app.component("base-container", BaseContainer);
import SectionDevider from "./assets/containers/SectionDevider.vue";
app.component("section-devider", SectionDevider);
import TitleStyle from "./assets/TitleStyle.vue";
app.component("title-style", TitleStyle);
import MoverComponent from "./assets/MoverComponent.vue";
app.component("mover-component", MoverComponent);


import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
library.add(fas, fab);
app.component("font-awesome-icon", FontAwesomeIcon);

import BaseDialog from "./assets/BaseDialog.vue";
app.component("base-dialog", BaseDialog);
import BaseSpinner from "./assets/BaseSpinner.vue";
app.component("base-spinner", BaseSpinner);

//create store
import { createStore } from "vuex";
import Store from "./store/index";
export const store = createStore(Store);
app.use(store);



app.mount("#app");