<template>
    <div class="astrodivider">
        <div class="astrodividermask"></div>
        <span>
            <i>
                <slot></slot>
            </i>
        </span>
    </div>
</template>
<style scoped lang="scss">
.astrodivider {
    margin: 50px auto 30px;
    width: 400px;
    max-width: 100%;
    position: relative;

    span {
        width: 70px;
        height: 70px;
        position: absolute;
        bottom: 100%;
        // margin-bottom: -25px;
        left: 50%;
        transform: translate(-50%, 50%);
        border-radius: 100%;
        box-shadow: 0 2px 4px #4fb39c;
        background: #fff;

        i {
            position: absolute;
            top: 4px;
            bottom: 4px;
            left: 4px;
            right: 4px;
            border-radius: 100%;
            border: 1px dashed #68beaa;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            text-transform: uppercase;
            color: #3b9ae7;
            font-size: 20px;
        }
    }
}

.astrodividermask {
    overflow: hidden;
    height: 20px;

    &:after {
        content: '';
        display: block;
        margin: -25px auto 0;
        width: 100%;
        height: 25px;
        border-radius: 125px / 12px;
        box-shadow: 0 0 8px #049372;
    }
}
</style>