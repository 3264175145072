<template>
  <title-style>portfolio</title-style>
  <p class="maxim">if you do it right, it will last forever</p>
  <mover-component />
  <!-- <section-container>-->
  <div class="filter">
    <div id="myBtnContainer">
      <span class="active" data-tp="all"> all</span>
      <span data-tp="personal"> Personal page</span>
      <span data-tp="social"> Social page</span>
      <span data-tp="global"> Global web page</span>
    </div>
  </div>
  <old-works />
  <!-- </section-container>   -->
</template>
<script>
import OldWorks from '@/components/portfolio/OldWorks.vue'
export default {
  // created() {
  //   document.title = 'Hasan Yameen Portfolio';
  // },
  components: {
    OldWorks,
  },
  mounted() {
    let btns = document.querySelectorAll('#myBtnContainer span')
    // console.log(btns)
    btns.forEach((btn) => {
      btn.addEventListener('click', function () {
        // console.log(btn)
        // remove class active fromm last active
        var current = document.querySelectorAll('#myBtnContainer .active')
        current[0].classList.remove('active')

        // get all elements will be filtered
        let allElm = document.querySelectorAll('#project-cards .filterDiv')

        // check type of sellected btn
        if (btn.getAttribute('data-tp') === 'all') {
          // add show class to all elements
          allElm.forEach((elm) => {
            elm.classList.add('show')
          })
        } else {
          // show class will be added to current type of filter
          allElm.forEach((elm) => {
            let s = btn.getAttribute('data-tp')
            if (s !== null) {
              if (elm.classList.contains(s)) {
                elm.classList.add('show')
              } else {
                elm.classList.remove('show')
              }
            }

          })
        }
        // add class active to current btn
        btn.classList.add('active')
      })
    })
  }
}
</script>

<style scoped lang="scss">
@import '../scss/vars';

.filter {
  margin-bottom: 30px;

  span {
    padding: 5px;

    &:hover {
      border-bottom: 2px solid $bg-clr-2;
      color: $fnt-clr-4;
      cursor: pointer;
    }

    &.active {
      color: $fnt-clr-4;
    }
  }
}
</style>
