import { default } from '../views/PortfolioView.vue';
<template>
  <footer>
    <section class="cards">
      <div class="card sites">
        <h6 class="card-title">Other Sites</h6>
        <div class="site" v-for="site in sites" :key="site.id">
          <!-- <div class="logo"><img :src="site.icon" :alt="site.iconAltTxt"></div> -->
          <a :href="site.siteUrl" target="_blank">{{ site.siteName }}</a>
        </div>
      </div>

      <div class="card address">
        <h6 class="card-title">Contact</h6>
        <div>
          <!-- <div class="logo"><font-awesome-icon icon="fa-solid fa-location-dot" /></div> -->
          Kocaeli, Türkiye
        </div>
        <div>
          <!-- <div class="logo"><font-awesome-icon icon="fa-solid fa-envelope" /></div> -->
          <a :href="mailContent"> info@hasanyameen.com</a>
        </div>
      </div>

      <div class="card social">
        <h6 class="card-title">find me</h6>
        <div class="links">
          <div class="link" v-for="link in links" :key="link.id">
            <a :href="link.url" target="_blank">
              <font-awesome-icon :icon="link.icon" :style="{ color: link.brandColor }" />
            </a>
          </div>
        </div>

      </div>
    </section>
    <div class="copyright">
      Copyright ©2024 All Rights Reserved by Hasan Yameen
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      mailContent: `mailto:info@hasanyameen.com?subject=Request For Offer&body=Hello Mr.Hasan ...`,
      sites: [
        {
          id: '1',
          icon: '',
          iconAltTxt: 'Logo',
          siteName: 'Real estate',
          siteUrl: 'https://hyconsultant.hasanyameen.com'
        },
      ],
      links: [
        {
          id: '1',
          name: 'linkedin',
          url: 'https://www.linkedin.com/in/hasanyameen13/',
          icon: 'fa-brands fa-linkedin',
          brandColor: '#0077b5',
        },
        {
          id: '2',
          name: 'github',
          url: 'https://github.com/hasan-yamin',
          icon: 'fa-brands fa-github',
          brandColor: '#333',

        },
        {
          id: '3',
          name: 'instagram',
          url: 'https://www.instagram.com/hy__coding/',
          icon: 'fa-brands fa-instagram',
          brandColor: '#fd1d1d',

        },
        // {
        //   id: '4',
        //   name: 'facebook',
        //   url: '',
        //   icon: "fa-brands fa-square-facebook",
        //   brandColor: '#1877f2',

        // },
        // {
        //   id: '5',
        //   name: 'youtube',
        //   url: '',
        //   icon: "fa-brands fa-youtube",
        //   brandColor: '#ff0000',

        // },
        // {
        //   id: '6',
        //   name: 'whatsapp',
        //   url: '',
        //   icon: "fa-brands fa-whatsapp",
        //   brandColor: '#25d366',
        // },
      ],
    }
  }
}
</script>
<style scoped lang="scss">
@import '../scss/vars';

footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 50px;
  width: 100%;
  height: 100vh;
  background-color: $bg-footer;

  .cards {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;

    @media (max-width: 766px) {
      flex-direction: column;
    }

    .card {
      width: 30%;
      display: flex;
      align-items: center;
      flex-direction: column;

      @media (max-width: 766px) {
        width: 100%;
        margin-top: 10px;
        // border-bottom: 0.5px solid $fnt-clr-2;
      }

      .card-title {
        font-size: 20px;
        font-weight: bold;
        text-transform: uppercase;
        color: $fnt-clr-1;
        margin-bottom: 16px;
      }

      div {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-weight: normal;
        font-size: 15px;
        text-transform: capitalize;
        line-height: 25px;
        color: $fnt-clr-3;

        a {
          text-decoration: none;
          color: $fnt-clr-3;
          margin: 5px;
          padding: 5px;

          &:hover {
            cursor: pointer;
          }
        }
      }

      &.social {
        .links {
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          align-items: center;
          flex-wrap: wrap;

          .link {

            a {
              display: inline-block;
              text-decoration: none;
              width: 30px;
              height: 30px;
              display: flex;
              justify-content: center;
              align-items: center;

              svg {
                font-size: 25px;

                &:hover {
                  cursor: pointer;
                  font-size: 30px;
                }
              }
            }
          }
        }
      }
    }
  }

  .copyright {
    // width: 100%;
    font-size: 20px;
    color: $fnt-clr-2;

    @media (max-width: 766px) {
      font-size: 13px;
    }
  }
}
</style>
