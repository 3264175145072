import mutations from "./mutations.js";
import actions from "./actions.js";
import getters from "./getters.js";

export default {
  namespaced: true,
  state() {
    return {
      oldWorks: [
        {
          id: "1",
          projectName: "Let's Do it",
          topic: "Global web page",
          filterName: "global",
          imgSrc: require("../../../../public/lets-do-it.png"),
          imgAlt: "Let's Do it",
          url: "https://frontend.hasanyameen.com/to_do/",
          description:
            "To-do web site, from this site you can crteate your own account and start add your to-to lists, you have choices to edit task name or delete task, also you can check on task to mark it as done task\n HTML5,CSS3,pure JS,JSON",
        },
        {
          id: "2",
          projectName: "one-page personal portfolio",
          topic: "personal page",
          filterName: "personal",
          imgSrc: require("../../../../public/s-p-p-1.png"),
          imgAlt: "Social page template",
          url: "https://hasan-yamin.github.io/one-page-portfolio-website-1/",
          description:
            "a one-page portfolio paage, using this page you can show your portfolio to more pepole arround the world\n HTML5,CSS3,pure JS,JSON",
        },
        {
          id: "3",
          projectName: "Social page",
          topic: "Social page",
          filterName: "social",
          imgSrc: require("../../../../public/social-page.png"),
          imgAlt: "Social page template",
          url: "https://hasan-yamin.github.io/-Boxmind-social-page/",
          description: "",
        },
        {
          id: "4",
          projectName: "commercial one-page",
          topic: "commercial page",
          filterName: "global",
          imgSrc: require("../../../../public/commercial-one-page.png"),
          imgAlt: "Social page template",
          url: "https://hasan-yamin.github.io/IT-SOLUTUON-page/",
          description: "",
        },
        {
          id: "5",
          projectName: "one-page personal portfolio",
          topic: "personal page",
          filterName: "personal",
          imgSrc: require("../../../../public/one-page-portfolio.png"),
          imgAlt: "Social page template",
          url: "https://hasan-yamin.github.io/Template3-html-css-/#features",
          description:
            "a one-page portfolio paage, using this page you can show your portfolio to more pepole arround the world\n HTML5,CSS3,pure JS,JSON",
        },
        {
          id: "6",
          projectName: "one-page personal photography portfolio",
          topic: "personal page",
          filterName: "personal",
          imgSrc: require("../../../../public/photography-one-page.png"),
          imgAlt: "personal page",
          url: "",
          description:
            "a one-page portfolio paage, using this page you can show your portfolio to more pepole arround the world\n Vue.js,JSON",
        },
        {
          id: "7",
          projectName: "Book Shop Web Site",
          topic: "commercial",
          filterName: "global",
          imgSrc: require("../../../../public/bookShop2.png"),
          imgAlt: "Social page template",
          url: "https://hasan-yamin.github.io/pitonshop/",
          description:
            "multiple-pages Book shop site, using this site you can show your products to more pepole arround the world\n HTML5,CSS3,Typescript,JSON",
        },
      ],
    };
  },
  mutations,
  actions,
  getters,
};
