<template>
  <base-spinner v-if="showLoadingDialog"></base-spinner>
  <base-dialog @close="closeDialog" @confifrm="closeDialog" :show="showErrorDialog" title="Error">
    <!-- <p> {{ ErrorMsg }} </p> -->
  </base-dialog>

  <div class="service-details">
    <div class="title"> {{ getServiceDetails.serviceTitle }} </div>

    <div class="description"> {{ getServiceDetails.serviceDesc }} </div>
    <div class="features">
      <h3>Features:</h3>
      <div class="feature" v-for="feature in getServiceDetails.features" :key="feature.id">
        <p class="featureName">{{ feature.featureName }}</p>
        <span class="featureDesc">{{ feature.featureDesc }}</span>
      </div>
    </div>

    <!-- <div class="examples">
      <h3>Examples</h3>
      <ProductImgSlider :images="img" />
    </div> -->
  </div>
</template>
<script>
export default {
  props: ['serviceId'],
  data() {
    return {
      ErrorMsg: '',
      showErrorDialog: false,
      showLoadingDialog: false,
      // service: {},
    }
  },
  created() {
    this.loadServices();
  },
  computed: {
    getServiceDetails() {
      let services = this.$store.getters["services/getServices"];
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      // this.service = null;
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      // this.service = services.find((s) => s.serviceName === this.serviceId);
      // return true
      return services.find((s) => s.serviceName === this.serviceId);

    },
  },
  methods: {
    closeDialog() {
      this.showErrorDialog = false
      this.ErrorMsg = ''
    },
    async loadServices() {
      this.showLoadingDialog = true;
      console.log("load  Services from DB ...");
      try {
        await this.$store.dispatch("services/loadServices");
        // this.getServiceDetails()
      }
      catch (error) {
        this.showErrorDialog = true
        this.ErrorMsg = error.message || "Something went wrong while loading Services from DB!";
      }
      this.showLoadingDialog = false;
    },
  },
}
</script>
<style lang="scss" scoped>
@import '../../scss/vars';

.service-details {
  width: 80%;
  margin: auto;
  padding-top: 50px;

  @media (max-width: 767px) {
    width: 95%;
  }

  .title {
    text-transform: capitalize;
    font-weight: bold;
    line-height: 30px;
    font-size: 20px;
    text-align: left;
    margin-bottom: 10px;
    padding-left: 15px;
    background-color: $bg-clr-2;
  }

  .description {
    text-align: start;
    color: $fnt-clr-1;
    line-height: 23px;
    box-shadow: $bg-clr-2 0px 3px 6px, $bg-clr-2 0px 3px 6px;
    padding: 15px;
    margin-bottom: 20px;
  }

  .features {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 15px;
    padding-bottom: 20px;

    h3 {
      width: 100%;
      text-transform: capitalize;
      font-weight: bold;
      line-height: 30px;
      font-size: 20px;
      text-align: left;
      padding-left: 15px;
      background-color: $bg-clr-2;

    }

    .feature {
      width: 100%;
      padding: 15px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 10px;
      background-color: $bg-clr-1;
      border-radius: 15px;

      .featureName {
        text-align: start;
        padding-left: 10px;
        color: $fnt-clr-4;
        line-height: 23px;
      }

      .featureDesc {
        text-align: start;
        padding-left: 10px;
        color: $fnt-clr-1;
        line-height: 23px;
      }
    }
  }
}

.examples {
  margin: 20px auto;

  h3 {
    width: 100%;
    text-transform: capitalize;
    font-weight: bold;
    line-height: 30px;
    font-size: 20px;
    text-align: left;
    padding-left: 15px;
    background-color: $bg-clr-2;

  }
}

.image {
  width: 30%;
  height: 300px;
  // border: 1px solid;

  @media (max-width: 767px) {
    width: 100%;
  }
}
</style>
